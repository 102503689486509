.btn {
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  outline-offset: 5px;
  min-width: 120px;
}

.primaryBtn {
  background-color: rebeccapurple;
  border: 2px solid rebeccapurple;
  color: white;
}

.primaryBtn:hover,
.primaryBtn:focus-visible {
  background-color: rgb(86, 37, 134);
  border-color: rgb(86, 37, 134);
}

.secondaryBtn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.secondaryBtn:hover,
.secondaryBtn:focus-visible {
  background-color: white;
  color: #282c34;
}

.btn[aria-disabled=true] {
  background-color: grey;
  border-color: grey;
  color: white;
  cursor: default;
}

@media (min-width: 900px) {
  .btn {
    font-size: 20px;
    width: 200px;
  }
}