.container {
  max-width: 300px;
  border: 1px solid white;
  padding: 20px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
}

.container,
.capacityForm,
.capacityField {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container,
.capacityForm {
  width: 100%;
  justify-content: space-between;
}

.capacityField {
  width: 50%;
  align-items: flex-start;
  gap: 5px;
}

.capacityField label {
  font-size: 16px;
}

.capacityField input {
  width: 100%;
}

.startBtn {
  margin-top: 15px;
  width: unset;
}

@media (min-width: 900px) {
  .container {
    font-size: 24px;
    max-width: 450px;
  }
}